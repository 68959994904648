// src/components/Footer.js
import { Box, Container, Grid, Typography, IconButton, Link } from '@mui/material';

import InstagramIcon from '@mui/icons-material/Instagram';


const Footer = () => {
  return (
    <Box component="footer" sx={{ bgcolor: 'primary.main', color: 'white', py: 6, mt: 'auto' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Weaver's Woodworks
            </Typography>
            <Typography variant="body2">
              Custom CNC woodworking and handcrafted furniture
              <br />
              Workshop located in West Knoxville
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Hours
            </Typography>
            <Typography variant="body2">
              Monday - Sunday: By appointment
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Connect With Us
            </Typography>
            <IconButton color="inherit" component={Link} href="https://www.instagram.com/weaver.woodworks/" target="_blank">
              <InstagramIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ mt: 4, textAlign: 'center' }}>
          © {new Date().getFullYear()} Weaver's Woodworks. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;