// src/pages/Products.js
import { Container, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';

const products = [
  { id: 1, name: 'Helmet Bowl', image: '/img/helmettray.jpg', description: 'Hand-crafted decorative bowl' },
  { id: 2, name: 'Sunsphere Tray', image: '/img/sunsphere.jpg', description: 'Precision cut tray with Sunsphere relief' },
  // Add more products
];

const Products = () => {
  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h3" gutterBottom>Our Products</Typography>
      <Grid container spacing={4}>
        {products.map((product) => (
          <Grid item key={product.id} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={product.image}
                alt={product.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  {product.name}
                </Typography>
                <Typography color="text.secondary">
                  {product.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Products;