// src/pages/About.js
import { Container, Typography, Box } from '@mui/material';

const About = () => {
  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h3" gutterBottom>About Us</Typography>
      <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
        <Typography paragraph>
          We specialize in creating custom woodwork using our self-built CNC machine, 
          combining traditional craftsmanship with modern technology.
        </Typography>
        <Typography paragraph>
          Our CNC machine, built from an open-source project, allows us to create 
          intricate designs with precision while maintaining the warmth and character 
          of handcrafted woodwork.
        </Typography>
      </Box>
    </Container>
  );
};

export default About;