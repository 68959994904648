// src/pages/Contact.js
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute('6LdlSJAqAAAAAKZ-vPN1HtP_vwp3AmyEerdN-M_I', { action: 'submit' });
        const response = await axios.post('/send', { ...formData, token });
        console.log(response.data);
        alert('Message sent successfully');
        setFormData({ name: '', email: '', message: '' });
        navigate('/'); // Redirect to home
      } catch (error) {
        console.error(error);
        alert('Failed to send message');
      }
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h3" gutterBottom>Contact Us</Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600, mx: 'auto' }}>
        <TextField
          fullWidth
          margin="normal"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Message"
          name="message"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}
        >
          Send Message
        </Button>
      </Box>
    </Container>
  );
};

export default Contact;