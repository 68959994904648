// src/pages/Home.js
import { Box, Typography, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <>
      <Box
        sx={{
          height: '70vh',
          backgroundImage: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("/img/cnc_machine.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          textAlign: 'center'
        }}
      >
        <Container>
          <Typography variant="h2" gutterBottom>
            Custom CNC Woodworking
          </Typography>
          <Typography variant="h5" gutterBottom>
            Handcrafted with precision and care
          </Typography>
          <Button variant="contained" component={Link} to="/products" size="large">
            View Our Work
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default Home;